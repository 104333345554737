import styled from 'styled-components';
import {
	setFontSize,
	setFontFamily,
	setFontWeight,
	setColors,
	mediaQuery,
} from '../../../../utils/helpers';
import inactiveArrow from '../../../images/bg/arrow.png';
import activeArrow from '../../../images/bg/arrow-blue.png';

export const FaqWrapper = styled.div`
	margin-left: -15px;
	margin-right: -15px;
	display: grid;
	justify-content: center;
	grid-template-columns: ${props =>
		props.hideTitleColumn ? '0.725fr' : '4fr 8fr'};
	${mediaQuery.tabletL`
		grid-template-columns: 1fr;
	`}
`;

export const Left = styled.div`
	padding: 0 15px;
	h3 {
		margin: 0;
		font-family: ${setFontFamily.black};
		font-size: ${props =>
			props.largeTitle ? setFontSize.mediumLarge : setFontSize.mediumSmall};
		font-weight: ${setFontWeight.extraBold};
		line-height: 1.11;
		letter-spacing: -1.5px;
		margin-bottom: 30px;
		text-transform: none;
	}
	p {
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.verySmall};
		line-height: 1.3;
		font-weight: ${setFontWeight.normal};
		letter-spacing: normal;
		margin: 0.85em 0;
	}
	a {
		text-decoration: none;
		outline: none;
		color: ${props =>
			props.primaryColor ? props.primaryColor : setColors.themeBlue};
	}
	${mediaQuery.laptopXL`
		h3{
			font-size: ${props =>
				props.largeTitle ? setFontSize.veryLarge : setFontSize.mediumSmall};
		}
		p{
			font-size: ${setFontSize.mediumTiny};
		}
	`}
	${mediaQuery.tabletL`text-align : ${props => props.titleAligmet}`};
	${mediaQuery.mobileXL`
		h3{
			font-size: ${props =>
				props.largeTitle ? setFontSize.mediumSmall : setFontSize.large};
			text-align: center;
		}
		p{
			text-align: center;
			margin-bottom: 30px;
		}
	`}
`;
export const Right = styled.div`
	padding: 0 15px;
	a {
		text-decoration: none;
		outline: none;
		color: ${props =>
			props.primaryColor ? props.primaryColor : setColors.themeBlue};
	}
	.faq-container {
		padding: 15px;
		padding-left: 0;
		border-bottom: 1px solid ${setColors.transparentGray};
	}
	.question-container {
		margin: 15px;
		margin-left: 0;
		display: flex;
		cursor: pointer;
		justify-content: space-between;
		&:hover {
			color: ${props =>
				props.primaryColor ? props.primaryColor : setColors.themeBlue};
		}
	}
	.down-arrow {
		background-image: url('${inactiveArrow}');
		background-repeat: no-repeat;
		background-size: 17px auto;
		width: 20px;
		background-position: 0 5px;
	}
	.question-container:hover .down-arrow {
		background-image: url('${activeArrow}');
	}
	.question-text {
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.tiny};
		font-weight: ${setFontWeight.normal};
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		line-height: 1;
		width: calc(100% - 22px);
	}
	.active-question {
		color: ${props =>
			props.primaryColor ? props.primaryColor : setColors.themeBlue};
	}
	.answer {
		overflow: hidden;
		transition: height 0.35s ease;
	}
	.answer-container {
		border-top-right-radius: 5px;
		border-top-left-radius: 18px;
		border-bottom-left-radius: 18px;
		border-bottom-right-radius: 18px;
		padding: 15px;
		padding-top: 5px;
		padding-right: 35px;
		padding-left: 0;
	}
	.answer-text {
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.tiny};
		font-weight: ${setFontWeight.normal};
		font-style: normal;
		font-stretch: normal;
		line-height: 27px;
		letter-spacing: normal;
		text-align: left;
		color: ${setColors.lightBlack};
	}
	ol,
	ul {
		margin: 0;
	}
	ul {
		padding-left: 25px;
		li {
			padding: 3px 0;
		}
	}
	ol {
		padding-left: 30px;
		li {
			padding: 3px 0;
		}
	}
	table {
		border-spacing: 0;
	}
	th {
		color: ${setColors.silverGray};
		font-family: ${setFontFamily.bold};
		font-weight: ${setFontWeight.bold};
	}
	thead {
		background-color: ${setColors.tableGray};
	}
	tbody tr {
		&:nth-child(odd) {
			background-color: ${setColors.tableGray};
		}
		&:nth-child(even) {
			background-color: ${setColors.white};
		}
	}
	thead th {
		border: 1px solid ${setColors.gainsBoro};
		border-left: none;
		&:nth-of-type(1) {
			border-left: 1px solid ${setColors.gainsBoro};
		}
	}
	tbody > tr:nth-of-type(1) > th {
		border: 1px solid ${setColors.gainsBoro};
	}
	tbody > tr:nth-of-type(1) > td {
		border: 1px solid ${setColors.gainsBoro};
		border-left: none;
	}
	tbody th {
		border: 1px solid ${setColors.gainsBoro};
		border-top: none;
	}
	tbody td {
		border: 1px solid ${setColors.gainsBoro};
		border-top: none;
		border-left: none;
	}
	th,
	td {
		padding: 9px 12px;
		font-size: ${setFontSize.veryVeryTiny};
	}
	.pt-30 {
		padding-top: 30px;
	}
	.show-button {
		font-family: ${setFontFamily.bold};
		cursor: pointer;
		color: ${props =>
			props.primaryColor ? props.primaryColor : setColors.themeBlue};
		font-size: ${setFontSize.littleTiny};
		display: flex;
		align-items: center;

		svg {
			width: 15px;
			height: 15px;
			margin-left: 5px;
		}
	}
	${mediaQuery.laptopXL`
		.answer-text{
			font-size: ${setFontSize.mediumTiny};
		}
		.pt-30 {
			padding-top: 20px;
		}
	`}
	${mediaQuery.tabletL`
		.question-text{
			font-size: ${setFontSize.mediumTiny};
		}
		.answer-text{
			font-size: ${setFontSize.veryTiny};
		}
		.pt-30 {
			padding-top: 15px;
		}
	`}
	${mediaQuery.mobileXL`
		.faq-container{
			padding: 20px 0;
		}
		.question-container{
			margin: 8px 0;
			align-items: center;
		}
		.answer-container{
			padding: 0;
		}
		.down-arrow{
			height: 8px;
			background-size: 13px auto;
			background-position: initial;
		}
	`}
	@media(max-width: 350px) {
		th,
		td {
			padding: 5px;
			font-size: ${setFontSize.tiniest};
		}
	}
`;
