import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';
import bg1 from '../../images/bg/background-image-1.png';
import bg2 from '../../images/bg/background-image-2.png';
import bg3 from '../../images/bg/background-image-3.png';
import bg4 from '../../images/bg/background-image-4.png';

export const BgWrapper = styled.div`
	background-image: url('${bg3}'), url('${bg4}'), url('${bg1}'), url('${bg2}'),
		url('${bg3}'), url('${bg4}');
	background-position: 100% -151px, 0% 1315px, 100% 2260px, 0% 3732px,
		100% 5305px, 0% 6600px;
	background-size: 52% auto;
	z-index: -1;
	background-repeat: no-repeat;
	margin-top: 2px;
	${mediaQuery.mobileXL`background-image: none;margin-top: 0`};
	#use-case {
		margin-top: 182px;
		div {
			margin-bottom: 20px;
		}
		${mediaQuery.tabletL`margin-top: 160px`}
		${mediaQuery.mobileXL`margin-top: 50px; margin-bottom: -60px;`}
	}
`;

export const HeaderWithIcon = styled.div`
	text-align: center;
	margin-top: 151px;
	${mediaQuery.mobileXL`margin-top: 38px;`};
	div {
		margin-top: -21px;
		margin-bottom: 75px;
		width: 100%;
		text-align: center;
		box-sizing: border-box;
		${mediaQuery.mobileXL`padding-top: 0%; margin-top: -25px; width: 541px;margin-left: auto; margin-right: auto; margin-bottom: -20px;`};
		${mediaQuery.mobileM`width: 240px; margin-left: auto; margin-right: auto;`}
		br {
			${mediaQuery.mobileXL`display: none`};
		}
	}
	#whatsapp-title {
		margin-bottom: 2px !important;
		margin-top: 10px !important;
		width: 100%;
		br {
			display: none;
			${mediaQuery.mobileXL`display: block `};
		}
	}
`;

export const ImagesWrapper = styled.div`
	padding-top: 20px;
	${mediaQuery.mobileXL`padding-top: 40px;`};
	#feature-section {
		padding: 55px 0 70px 0;
		margin-bottom: 0;
		margin-top: 0;
		${mediaQuery.tabletL`padding: 45px 0`}
		${mediaQuery.mobileXL`padding: 20px 0 45px 0`}
	}
`;

export const WhatsappCTAWrapper = styled.div`
	padding: ${props => props.noBottomPadding ? `80px 0 0 0` : `80px 0`};
	${mediaQuery.tabletL`padding: ${props => props.noBottomPadding ? '6% 0 0 0' : '6% 0'};`}
	${mediaQuery.mobileXL`padding: ${props => props.noBottomPadding ? '3% 0 0 0' : '3% 0'};`}
`;

export const MapWrapper = styled.div`
	padding-top: 120px;
	height: 100%;
	width: 100%;
	${mediaQuery.mobileXL`padding-top: 20px;`};
`;

export const StepsWrapper = styled.div`
	padding-top: 60px;
	svg {
		height: 111px;
		width: 111px;
	}

	${mediaQuery.tabletL`padding-top: 20px;`};
	${mediaQuery.mobileXL`padding-top: 0px;`};
`;

export const CustomersWrapper = styled.div`
	${mediaQuery.tabletL`margin-top: -80px;`};
	${mediaQuery.mobileXL`margin-top: -50px;`};
`;

export const WhatsappCTAWrapper2 = styled.div`
	padding: 70px 0;
	${mediaQuery.tabletL`padding: 3% 0;`}
`;

export const FAQWrapper = styled.div`
	padding-top: 158px;
	.faq-title-image {
		margin-left: -23px;
		margin-top: -23px;
		margin-bottom: -15px;
		${mediaQuery.tabletL`margin-left: 0`}
	}
	${mediaQuery.tabletL`padding-top: 144px;`}
	${mediaQuery.mobileXL`padding-top: 94px;`}
`;

export const CTAWrapper = styled.div`
	padding: 190px 0 20px 0;
	${mediaQuery.tabletL`padding: 150px 0 20px 0;`}
	${mediaQuery.mobileXL`padding: 96px 0 20px 0;`}
`;
