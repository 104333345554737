import React from 'react';
import * as S from './styles';
import Spanner from '../../../svg/spanner.inline.svg';

const Steps = ({ section7Content, titleIcon }) => {
	const { title, subtitle, content } = section7Content;
	return (
		<S.Wrapper>
			<S.Header>
				{titleIcon ? titleIcon : <Spanner />}
				<h2>{title}</h2>
				<p>{subtitle}</p>
			</S.Header>
			<S.CardHolder>
				<div className="subClassHolder">
					<S.Card>
						<p className="number">{content[0].id}</p>
						<p className="title">{content[0].title}</p>
						<p className="subtitle">{content[0].subtitle}</p>
					</S.Card>
					<S.Card className="tabletAbove">
						<p className="number">{content[2].id}</p>
						<p className="title">{content[2].title}</p>
						<p className="subtitle">{content[2].subtitle}</p>
					</S.Card>
					<S.Card className="mobileOnly">
						<p className="number">{content[1].id}</p>
						<p className="title">{content[1].title}</p>
						<p className="subtitle">{content[1].subtitle}</p>
					</S.Card>
				</div>
				<div className="subClassHolder">
					<S.Card className="mobileOnly">
						<p className="number">{content[2].id}</p>
						<p className="title">{content[2].title}</p>
						<p className="subtitle">{content[2].subtitle}</p>
					</S.Card>
					<S.Card className="tabletAbove">
						<p className="number">{content[1].id}</p>
						<p className="title">{content[1].title}</p>
						<p className="subtitle">{content[1].subtitle}</p>
					</S.Card>
					<S.Card>
						<p className="number">{content[3].id}</p>
						<p className="title">{content[3].title}</p>
						<p className="subtitle">{content[3].subtitle}</p>
					</S.Card>
				</div>
			</S.CardHolder>
		</S.Wrapper>
	);
};

export default Steps;
