import React from 'react';
import PropTypes from 'prop-types';
import IconRoot from './icon-root';

const IconNext = ({ height, width, ...rest }) => (
	<IconRoot width={width} height={height} viewBox="0 0 24 9.642" {...rest}>
		<path
			xmlns="http://www.w3.org/2000/svg"
			id="prefix__Shape_10_copy_6"
			d="M1169 3516.874v-2.124h18.5v-3.72a1.415 1.415 0 0 1 1.38.336c1.272 1.416 2.592 2.784 3.888 4.164.072.084.144.168.228.276-.108.108-.192.228-.3.324-1.236 1.32-2.5 2.616-3.7 3.972a1.341 1.341 0 0 1-1.476.444v-3.672z"
			data-name="Shape 10 copy 6"
			transform="translate(-1169 -3510.972)"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</IconRoot>
);

IconNext.defaultProps = {
	height: 20,
	width: 20,
};

IconNext.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
};

export default IconNext;
