import styled from 'styled-components';
import { mediaQuery, setColors } from '../../../../utils/helpers';

export const Main = styled.div`
	width: 100%;
	padding-top: 40px;
	border-bottom: 1px solid ${setColors.divider};
`;

export const Wrapper = styled.div`
	display: table;
	vertical-align: baseline;
	width: 100%;
`;

export const TextContent = styled.div`
	box-sizing: border-box;
	width: 40%;
	display: table-cell;
	vertical-align: middle;
	padding: 0 4% 0 0 !important;
	${mediaQuery.mobileXL`padding: 0 !important`}
`;

export const SectionTitle = styled.h1`
	padding-bottom: 6%;
	vertical-align: middle;
	color: ${setColors.lightBlack};
	${mediaQuery.mobileXL`
    text-align: center;
    margin: 0 22px; 
    width: 220px !important;
    margin-left: auto;
    margin-right: auto;`};
`;

export const SectionDiscription = styled.div`
	padding-bottom: 12%;
	margin: 0;
	width: 450px;
	${mediaQuery.laptopXL`width: auto !important;`}
	${mediaQuery.mobileXL`text-align: center;
    margin: 0 22px; 
    width: 220px !important;
    margin-left: auto;
    margin-right: auto;`}
`;

export const SectionButton = styled.div`
	${mediaQuery.mobileXL`
    justify-content: center;
    display: flex;
    margin-bottom: 54px;`}
`;

export const ImageWrapper = styled.div`
	img {
		width: 100%;
		vertical-align: middle;
		${props => props.imgStyle}
	}
	width: ${props => props.imgSectionWidth};
	display: table-cell;
	padding: 0 !important;
	overflow: hidden;
	vertical-align: baseline;
	${mediaQuery.tabletL`width: 65%`};
	${mediaQuery.mobileXL`display: none;`};
`;
