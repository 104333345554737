import styled from 'styled-components';
import {
	media,
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';
import Img from 'gatsby-image';

export const Wrapper = styled.div`
	display: grid;
	width: 100%;
	margin-bottom: 20px;
	p:nth-of-type(1) {
		font-family: ${setFontFamily.black};
		margin: 0rem;
	}
	a {
		color: ${setColors.themeBlue};
		text-decoration: none;
		display: flex;
		align-items: center;
	}
	grid-template-columns: 1fr;
	padding: 0;
	div {
		margin: 0 15px;
	}
	p:nth-of-type(1) {
		font-size: ${setFontSize.large};
		font-weight: ${setFontWeight.bolder};
		line-height: 1.09;
		letter-spacing: -0.69px;
		font-family: ${setFontFamily.bold};
		margin: 10px 0;
	}
	p:nth-of-type(2),
	a {
		font-size: ${setFontSize.veryTiny};
		line-height: 1.5;
		font-family: ${setFontFamily.book};
		margin: 0;
	}
	div:nth-of-type(1) {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
	}
	padding-top: 90px;

	.svg-image {
		width: 100%;
		max-height: 420px;
		height: auto;
		${mediaQuery.mobileXL`width: calc(100% - 30px); margin: 0 15px;`}
	}

	${media.tablet`
		padding-top: 100px;
		margin-top: 50px;
		margin-bottom: 0;
		grid-template-columns: ${props =>
			props.invert ? '0.5fr 0.5fr' : '0.5fr 0.5fr'};
		div:nth-of-type(1) {
			order: ${props => (props.invert ? 1 : '')};
			padding: ${props => (props.invert ? '0 0 0 40px' : '0 40px 0 0')};
		}
		p:nth-of-type(1) {
			font-size: ${setFontSize.mediumSmall};
		}
		p:nth-of-type(2), a{
			font-size: ${setFontSize.veryTiny};
		}
    `};

	${media.desktop`
		grid-template-columns: 0.5fr 0.5fr;
		p:nth-of-type(1) {
			font-size: ${setFontSize.veryLarge};
		}
		p:nth-of-type(2), a{
			font-size: ${setFontSize.mediumTiny};
		}
	`}

	${media.large`
		padding-top: 150px;
		p:nth-of-type(1) {
			font-size: ${setFontSize.mediumLarge};
		}
		p:nth-of-type(2),a{
			font-size: ${setFontSize.tiny};
		}
	`}
`;

export const Image = styled(Img)`
	margin: 10px;
`;

export const RichTextnWrapper = styled.div`
	margin: 0 !important;
	padding: 0 !important;
	order: unset !important;
	p:nth-of-type(1) {
		font-size: ${setFontSize.veryTiny};
		line-height: 1.5;
		font-family: ${setFontFamily.book};
		margin: 0;
		font-weight: 100;
		letter-spacing: normal;
	}

	${media.tablet`
		p:nth-of-type(1){
			font-size: ${setFontSize.veryTiny};
		}
    `};

	${media.desktop`
		p:nth-of-type(1){
			font-size: ${setFontSize.mediumTiny};
		}
	`}

	${media.large`
		p:nth-of-type(1){
			font-size: ${setFontSize.tiny};
		}
	`}
`;

export const IconWrapper = styled.div`
	margin-left: 9px;
`;
