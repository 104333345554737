import styled from 'styled-components';
import {
	media,
	setFontFamily,
	setColors,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';
import { Link } from 'gatsby';

export const Wrapper = styled.section`
	background-color: ${setColors.lightBlack};
	color: ${setColors.white};
	border-radius: 23px;
	padding: 30px 0 3px;
	box-sizing: border-box;
	text-align: center;
	flex-direction: column;
	font-stretch: normal;
	display: flex;
	div:nth-of-type(1) {
		padding: 0 15px;
		h3 {
			font-size: ${setFontSize.mediumSmall};
			line-height: 1.38;
			font-weight: ${setFontWeight.bold};
			margin: 0;
			padding: 0;
			margin-bottom: 8px;
			font-family: ${setFontFamily.bold};
		}
		p {
			font-size: ${setFontSize.veryTiny};
			margin: 20px 0;
			line-height: 1.54;
			letter-spacing: -0.08px;
			font-family: ${setFontFamily.book};
			font-weight: ${setFontWeight.normal};
		}
	}
	div:nth-of-type(2) {
		padding: 25px 30px 30px;
		display: flex;
		flex-direction: column;
	}
	.blackCtaBtn {
		margin: 15px 0 0 0;
	}

	${media.tablet`
		margin-left: -15px;
		margin-right: -15px;
		padding: 70px;
		text-align: left;
		div:nth-of-type(1){
			width: 100%;
			h3{
				line-height: 1.09;
			}
			p{
				line-height: 1.2;
				letter-spacing: -0.11px;
				margin: 0.85em 0;
			}
		}
		div:nth-of-type(2){
			width: 100%;
			flex-direction: row;
			justify-content: flex-start;
			padding: 0;
			align-items:center;
			height: 157px;
			padding-right: 15px;
		}
		.blackCtaBtn {
			margin: 0 0 0 20px;
		}

	`}

	${media.desktop`
		flex-direction: row;
		justify-content: space-between;
		div:nth-of-type(1){
			width: 54.3333%;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
			h3{
				font-size: ${setFontSize.veryLarge};
			}
			p{
				font-size:${setFontSize.mediumTiny};
				margin: 0.85em 0 0 0;
			}
		}
		div:nth-of-type(2){
			width: 45.6667%;
			box-sizing: border-box;
			justify-content: flex-end;
			flex-wrap: wrap;
			padding: 0;
			padding-right: 15px;
		}

	`}
	@media (max-width: 1285px) and (min-width: 1000px) {
		div:nth-of-type(1) {
			width: 50%;
		}
		div:nth-of-type(2) {
			width: 50%;
		}
	}

	${media.large`
		div:nth-of-type(1){
			h3{
				font-size:${setFontSize.mediumLarge}; 
			}
			p{
				font-size: ${setFontSize.tiny};
			}
		}
		div:nth-of-type(2){
			flex-direction: row;
			align-items: center;
			height: 201px;
		}
	`}
`;

export const CTAButton = styled(Link)`
	background-color: ${props =>
		props.active ? setColors.white : setColors.lightBlack};
	color: ${props => (props.active ? setColors.lightBlack : setColors.white)};
	text-decoration: none;
	margin: 15px 0 0;
	max-width: 100%;
	border-radius: 4px;
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.bold};
	line-height: 1.2;
	letter-spacing: -0.11px;
	text-align: center;
	padding: 12px 25px;
	outline: 0;
	border: 3px solid ${setColors.white};

	&:hover {
		background-color: ${setColors.white};
		color: ${setColors.lightBlack};
	}

	${media.tablet`
		margin: 0 10px;
	`}
`;
