import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import RichTextRenderer from '../../global/RichTextRenderer';
import * as S from './styles';
import { IconNext } from '../icons';

const FeatureSnippet = ({
	image,
	title,
	subtitle,
	link,
	linkText,
	invert,
	richTextContent,
}) => {
	return (
		<S.Wrapper invert={invert} id={'feature-section'}>
			<div>
				<p>{title}</p>
				{subtitle && <p>{subtitle}</p>}

				{richTextContent && (
					<S.RichTextnWrapper>
						<RichTextRenderer content={richTextContent} />
					</S.RichTextnWrapper>
				)}

				{link && linkText && (
					<Link to={`${link}/`} style={{ margin: '20px 0 30px 0' }}>
						{linkText}
						<S.IconWrapper>
							<IconNext height={15} width={15} />
						</S.IconWrapper>
					</Link>
				)}
			</div>
			{image?.file?.contentType === 'image/svg+xml' ? (
				<img src={image.file.url} alt={image.title} className="svg-image" />
			) : (
				<Img fluid={image.fluid} alt={image.title} />
			)}
		</S.Wrapper>
	);
};

export default FeatureSnippet;
