import React from 'react';
import * as S from './styles';
import Button from '../Button';
import { checkLink, setColors } from '../../../../utils/helpers';

export const ButtonTo = ({ url, children, ...otherProps }) => {
	const urlType = checkLink(url);
	if (urlType === 0) {
		return (
			<Button
				{...otherProps}
				as="a"
				href={url}
				target="_blank"
				rel="noopener noreferrer"
			>
				{children}
			</Button>
		);
	} else if (urlType === 1) {
		return (
			<Button {...otherProps} href={url} as="a" target="_self">
				{children}
			</Button>
		);
	} else {
		return (
			<Button {...otherProps} to={url}>
				{children}
			</Button>
		);
	}
};

const CTA = ({
	title,
	subtitle,
	customerPage,
	primaryButtonText = 'Get a Demo',
	primaryButtonUrl = '/demo',
	secondaryButtonText = 'Try for Free',
	secondaryButtonUrl = 'https://app.wotnot.io/signup',
}) => {
	return (
		<S.Wrapper customerPage={customerPage}>
			<div>
				<h3>{title}</h3>
				<p>{subtitle}</p>
			</div>
			<div>
				<ButtonTo
					url={primaryButtonUrl}
					btnColor={setColors.white}
					color={setColors.lightBlack}
					className="blackCtaBtn"
				>
					{primaryButtonText}
				</ButtonTo>
				{secondaryButtonText && secondaryButtonUrl && (
					<ButtonTo
						url={secondaryButtonUrl}
						btnColor={setColors.white}
						color={setColors.lightBlack}
						outline="true"
						className="blackCtaBtn"
					>
						{secondaryButtonText}
					</ButtonTo>
				)}
			</div>
		</S.Wrapper>
	);
};

export default CTA;
