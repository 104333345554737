import React from 'react';
import * as S from './style';

const Button = props => {
	const {
		children,
		to,
		as,
		className,
		href,
		target,
		style,
		btnColor,
		color,
		outline,
		rel,
		...other
	} = props;
	return (
		<S.Button
			as={as}
			to={`${to}/`}
			href={href}
			className={className}
			style={style}
			target={target}
			btnColor={btnColor}
			color={color}
			outline={outline}
			rel={rel}
			{...other}
		>
			{children}
		</S.Button>
	);
};
export const PlayButton = props => {
	const { playIcon, as, href, target, rel, btnColor, ...other } = props;
	return (
		<S.PlayButton
			as={as}
			href={href}
			btnColor={btnColor}
			rel={rel}
			{...other}
		/>
	);
};
export default Button;
