import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import FeatureHero from '../components/global/FeatureHeroSection/FeatureHero';
import FeatureSnippet from '../components/global/feature-snippet/FeatureSnippet';
import BlueCTA from '../components/global/CTA/BlueCTA/BlueCTA';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import Steps from '../components/partner-page/steps/Steps';
import Customers from '../components/global/customers/Customers';
import Faq from '../components/global/Faq/Faq';
import CTA from '../components/global/CTA/CTA';
import { setColors } from '../../utils/helpers';
import { Container } from '../components/global/customers/styles';
import * as S from '../components/whatsapp-chatbot/styles';
import Spanner from '../svg/how-it-work.svg';

const WhatsappChatbotTemplate = ({ data }) => {
	const {
		lang,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
		section1title,
		section1description,
		section1ButtonLink,
		section1ButtonText,
		section1image,
		section2IconTitle,
		section2Images,
		whatsAppCta,
		mapIconTitle,
		map,
		useCaseIconTitle,
		useCase,
		useCaseLogos,
		section6Content,
		section7IconTitle,
		section7Logos,
		whatsAppCta2,
		faqIconTitle,
		faq,
		cta,
	} = data.contentfulWhatsappChatbotTemplate;
	const useCaseContent = useCase.map((el, index) => {
		return { ...el, logo: useCaseLogos[index].file.url };
	});
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			<Helmet
				htmlAttributes={{
					lang: lang,
				}}
			/>
			<FeatureHero
				title={section1title}
				subTitle={section1description}
				image={section1image}
				buttonText={section1ButtonText}
				buttonLink={section1ButtonLink}
				imageWrapperStyle={{ marginBottom: '-74%' }}
				imgStyle={{ marginTop: '-25px' }}
				imgSectionWidth="36%"
			/>

			<S.BgWrapper>
				<S.HeaderWithIcon>
					<img src={section2IconTitle.file.url} alt={section2IconTitle.title} />

					<div
						className={'font-black-46'}
						dangerouslySetInnerHTML={{ __html: section2IconTitle.title }}
					/>
				</S.HeaderWithIcon>
				<Container>
					<S.ImagesWrapper>
						{section2Images.map((item, index) => (
							<FeatureSnippet
								image={item}
								title={item.title}
								subtitle={item.description}
								invert={index % 2 === 0 ? false : true}
							/>
						))}
					</S.ImagesWrapper>
				</Container>
				<S.WhatsappCTAWrapper>
					<BlueCTA
						title={whatsAppCta.title}
						buttonText={whatsAppCta.buttonText}
						buttonLink={whatsAppCta.buttonLink}
						number={whatsAppCta.number}
					/>
				</S.WhatsappCTAWrapper>
				<S.HeaderWithIcon>
					<img
						src={mapIconTitle.file.url}
						alt={mapIconTitle.title}
						height="86px"
						width="86px"
					/>
					<div
						id={'whatsapp-title'}
						className={'font-black-46'}
						dangerouslySetInnerHTML={{ __html: mapIconTitle.title }}
					></div>
				</S.HeaderWithIcon>
				<Container>
					<S.MapWrapper>
						<Img
							fluid={map.fluid}
							style={{ width: '100%', maxHeight: '680px' }}
							imgStyle={{ objectFit: 'fill' }}
							alt={map.title}
						/>
					</S.MapWrapper>
				</Container>
				<S.HeaderWithIcon id={'use-case'}>
					<img src={useCaseIconTitle.file.url} alt={useCaseIconTitle.title} />

					<div
						className={'font-black-46'}
						dangerouslySetInnerHTML={{ __html: useCaseIconTitle.title }}
					/>
				</S.HeaderWithIcon>
				<VerticalSlider data={useCaseContent} isLogo />
				<Container>
					<S.StepsWrapper steps="true">
						<Steps section7Content={section6Content} titleIcon={<Spanner />} />
					</S.StepsWrapper>
				</Container>
				<S.CustomersWrapper>
					<Customers
						section2Images={section7Logos}
						section2Title={section7IconTitle.title}
						sectionIcon={section7IconTitle}
						customBgColor={setColors.white}
						imgStyle={{ objectFit: 'contain' }}
						style={{ maxHeight: '90px' }}
					></Customers>
				</S.CustomersWrapper>
				<S.WhatsappCTAWrapper2>
					<BlueCTA
						title={whatsAppCta2.title}
						buttonText={whatsAppCta2.buttonText}
						buttonLink={whatsAppCta2.buttonLink}
					/>
				</S.WhatsappCTAWrapper2>
				<Container>
					<S.FAQWrapper>
						<Faq
							title={faqIconTitle.title}
							content={faq}
							titleIcon={faqIconTitle}
							largeTitle
							titleAligmet={'center'}
						/>
					</S.FAQWrapper>
					<S.CTAWrapper>
						<CTA title={cta.title} subtitle={cta.subtitle} />
					</S.CTAWrapper>
				</Container>
			</S.BgWrapper>
		</Layout>
	);
};

export default WhatsappChatbotTemplate;

export const query = graphql`
	query($slug: String) {
		contentfulWhatsappChatbotTemplate(slug: { eq: $slug }) {
			lang
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
				title
			}
			seoJsonSchema {
				internal {
					content
				}
			}
			section1title
			section1description
			section1ButtonLink
			section1ButtonText
			section1image {
				fluid {
					...GatsbyContentfulFluid
				}
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
				title
			}
			section2IconTitle {
				title
				description
				file {
					url
				}
			}
			section2Images {
				title
				description
				fluid {
					...GatsbyContentfulFluid
				}
			}
			whatsAppCta {
				title
				buttonText
				buttonLink
			}
			mapIconTitle {
				title
				file {
					url
				}
			}
			map {
				title
				fluid {
					...GatsbyContentfulFluid
				}
			}

			useCaseIconTitle {
				title
				file {
					url
				}
			}
			useCase {
				title
				description
				fluid {
					...GatsbyContentfulFluid
				}
			}
			useCaseLogos {
				file {
					url
				}
			}
			section6Content {
				title
				subtitle
				content {
					id
					title
					subtitle
				}
			}
			section7IconTitle {
				title
				file {
					url
				}
			}
			section7Logos {
				title
				fluid {
					...GatsbyContentfulFluid
				}
			}
			whatsAppCta2 {
				title
				buttonText
				buttonLink
			}
			faqIconTitle {
				title
				file {
					url
				}
			}
			faq {
				question
				answer {
					type
					content
				}
			}
			cta {
				title
				subtitle
			}
		}
	}
`;
