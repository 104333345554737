import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ImageWrapper from '../ImageWrapper/ImageWrapper';
import { Container } from '../customers/styles';
import * as S from './styles';

export function useInterval(callback, delay) {
	const [intervalId, setIntervalId] = useState();

	useEffect(() => {
		let slideInterval = setInterval(callback, delay);
		setIntervalId(slideInterval);
		return () => clearInterval(slideInterval);
		// eslint-disable-next-line
	}, [delay]);
	return () => clearInterval(intervalId);
}

const VerticalSlider = ({
	data,
	isLogo,
	invert,
	theme,
	autoSlideTime, //total time for reading single slide (ms)
	gridLayout,
	cardPadding,
	cardAlignmet,
	borderRadius,
	imgStyle,
	cardStyle,
	realEstate,
	noBottomPadding,
}) => {
	const mainIntervalTime = 10; // time slice added in current reading porogress time (ms)
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);
	const [currentSliderTime, setCurrentSliderTime] = useState(0); // current slide reading porogress time (ms)
	const [processingWidth, setProcessingWidth] = useState(0);
	const [inProgress, setInProgress] = useState(true);

	const stopInterval = useInterval(() => {
		setCurrentSliderTime(prev => prev + mainIntervalTime);
	}, mainIntervalTime);

	const isActiveSlide = index => {
		return index === activeSlideIndex;
	};

	useEffect(() => {
		// code to change slide
		if (currentSliderTime > autoSlideTime) {
			setActiveSlideIndex(prev => (prev === data.length - 1 ? 0 : prev + 1));
			setCurrentSliderTime(0);
		}

		setProcessingWidth(((currentSliderTime * 100) / autoSlideTime).toFixed(2));
	}, [currentSliderTime, data.length, autoSlideTime]);

	const slideClick = index => {
		stopInterval();
		setInProgress(false);
		setActiveSlideIndex(index);
	};

	return (
		<Container>
			<S.Wrapper
				gridLayout={gridLayout}
				cardAlignmet={cardAlignmet}
				invert={invert}
				noBottomPadding={noBottomPadding}
			>
				{data.map((el, index) => {
					return (
						<S.ImageWrapper
							style={imgStyle}
							key={el.title}
							isActive={isActiveSlide(index)}
						>
							<ImageWrapper image={el} />
						</S.ImageWrapper>
					);
				})}
				<S.TextWrapper cardTheme={theme} invert={invert}>
					{data.map((el, index) => {
						return (
							<S.FeatureCard
								key={`${index}-${el.title}`}
								onClick={() => {
									slideClick(index);
								}}
								className={isActiveSlide(index) && 'active'}
								cardPadding={cardPadding}
								borderRadius={borderRadius}
								style={cardStyle}
							>
								<S.TitleWrapper>
									{isLogo && <img src={el.logo} alt={`${el.title} Icon`} />}

									<S.Content theme={theme}>
										<h4
											className={`${
												realEstate ? 'font-bold-20' : 'font-bold-26'
											}`}
										>
											{el.title}
										</h4>
										<p
											style={{
												display: isActiveSlide(index) ? 'block' : 'none',
											}}
										>
											{el.description}
										</p>
									</S.Content>
								</S.TitleWrapper>
								<S.ProgressBar
									isActive={isActiveSlide(index)}
									style={{
										width:
											inProgress && isActiveSlide(index)
												? `${processingWidth}%`
												: '0',
									}}
								></S.ProgressBar>
							</S.FeatureCard>
						);
					})}
				</S.TextWrapper>
			</S.Wrapper>
		</Container>
	);
};

VerticalSlider.defaultProps = {
	data: [],
	isLogo: false,
	invert: false,
	theme: 'light',
	autoSlideTime: 7000,
	gridLayout: '1fr 1fr',
	cardPadding: '3%',
	cardAlignmet: 'center',
	borderRadius: '20px',
	imgStyle: {},
	cardStyle: {},
	realEstate: false,
};

VerticalSlider.propTypes = {
	data: PropTypes.array,
	isLogo: PropTypes.bool,
	invert: PropTypes.bool,
	theme: PropTypes.string,
	autoSlideTime: PropTypes.number,
	gridLayout: PropTypes.string,
	cardPadding: PropTypes.string,
	cardAlignmet: PropTypes.string,
	borderRadius: PropTypes.string,
	imgStyle: PropTypes.object,
	cardStyle: PropTypes.object,
	realEstate: PropTypes.bool,
};

export default VerticalSlider;
