import React from 'react';
import Button from '../../Button/Button';
import { setColors } from '../../../../../utils/helpers';
import { Container } from '../../customers/styles';
import * as S from './styles';
import WhatsapImg from '../../../../images/Whatsapp-icon.png';

const BlueCTA = ({ title, number, buttonText, buttonLink }) => {
	return (
		<Container>
			<S.Wrapper>
				<S.Content>
					<S.TextWrapper>
						<S.CTAText>
							{title}
							{number && (
								<S.NumberContainer>
									{` ${number} `}
									<img src={WhatsapImg} alt="whatsApp" />
								</S.NumberContainer>
							)}
						</S.CTAText>
					</S.TextWrapper>
					<S.ButtoWrapper>
						<Button
							as="a"
							href={buttonLink}
							btnColor={setColors.lightBlack}
							color={setColors.white}
							target="_blank"
						>
							{buttonText}
						</Button>
					</S.ButtoWrapper>
				</S.Content>
			</S.Wrapper>
		</Container>
	);
};

export default BlueCTA;
