/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import styled, { css } from 'styled-components';
import { StyledReactModal } from '../newly-developed-pages/style';
import {
	checkLink,
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../utils/helpers';
import { RenderVideoEmbed } from '../../../utils/richtextHelper';
import ImageWrapper from './ImageWrapper/ImageWrapper';

const Wrapper = styled.section`
	margin-top: ${props => {
		if (props.blogContent && props.tocHeight) return css`-${props.tocHeight}px`;
	}};
	${mediaQuery.tabletL`
		margin-left: ${props => props.blogContent && props.tocHeight && '-60px'} 
	`}
	${mediaQuery.tabletXS`
		margin-left: 0;
		margin-top: 0
	`}
`;

const TableWrapper = styled.div`
	overflow-x: auto;
	padding: 1px 1px;
`;

const StyledTable = styled.table`
	margin-bottom: 1.5em;
	border-collapse: collapse;
	border-radius: 5px;
	border-style: hidden;
	box-shadow: rgb(174 193 204) 0px 0px 0px 1px;
	width: 100%;
	overflow: hidden;
`;

const StyledTh = styled.td`
	font-size: ${setFontSize.tiny};
	font-family: ${setFontFamily.book};
	color: ${setColors.lightBlack};
	font-weight: 600;
	background-color: ${setColors.warmGray};
	border: 1px solid ${setColors.tableHeaderBorder};
	padding: 10px 12px;
	text-align: left;
	font-size: 18px;
	${mediaQuery.tabletL`
		font-size: ${setFontSize.veryTiny}
	`}
	${mediaQuery.mobileXL`
		font-size: ${setFontSize.tiny}
	`}
`;

const StyledTr = styled.tr`
	transition: background-color 0.5s;
	:hover {
		background-color: ${setColors.lightGray};
	}
`;

const StyledTd = styled.td`
	vertical-align: top;
	font-size: ${setFontSize.tiny};
	font-family: ${setFontFamily.book};
	color: ${setColors.lightBlack};
	padding: 8px;
	border-bottom: 1px solid black;
	border: 1px solid rgb(0, 0, 0, 0.2);
	transition: background-color 0.4s;
	${mediaQuery.tabletL`
		font-size: ${setFontSize.veryTiny}
	`}
	${mediaQuery.mobileXL`
		font-size: ${setFontSize.tiny}
	`}
`;

const customStyles = {
	content: {
		position: 'relative',
		display: 'inline-block',
		margin: 'auto',
		inset: '0',
		boxSizing: 'border-box',
		outline: '0',
		padding: '0',
		border: 'none',
		verticalAlign: 'middle',
		width: '100%',
		maxWidth: '900px',
	},
	overlay: {
		backgroundColor: setColors.modalOverLayColor,
		zIndex: '1050',
		overflowX: 'hidden',
		overflowY: 'auto',
		textAlign: 'center',
		padding: '50px 6px',
		display: 'flex',
	},
};

const RichTextRenderer = ({ content, blogContent }) => {
	const [modal, setModal] = useState(false);
	const [currentlyOpenedImage, setCurrentlyOpenedImage] = useState({});
	const [tocHeight, setTocHeight] = useState('');
	const handleOpenModal = node => {
		setModal(!modal);
		if (!modal) setCurrentlyOpenedImage(node.data.target.fluid);
	};
	const options = {
		renderMark: {
			[MARKS.BOLD]: text => <strong>{text}</strong>,
		},
		renderNode: {
			[INLINES.HYPERLINK]: ({ data }, children) => {
				const urlType = checkLink(data.uri);
				if (urlType === 0) {
					return (
						<a href={data.uri} target="_blank" rel="noopener noreferrer">
							{children}
						</a>
					);
				} else if (urlType === 1) {
					if (
						data.uri.startsWith('http://wotnot.io') ||
						data.uri.startsWith('https://wotnot.io')
					) {
						const url = new URL(data.uri);
						if (url.pathname === '/')
							return <Link to={`/${url.search}`}>{children}</Link>;
						return <Link to={`${url.pathname}/${url.search}`}>{children}</Link>;
					}
					return (
						<a href={data.uri} target="_self">
							{children}
						</a>
					);
				} else {
					if (data.uri === '/')
						return <Link to={`${data.uri}`}>{children}</Link>;
					return <Link to={`${data.uri}/`}>{children}</Link>;
				}
			},
			[BLOCKS.PARAGRAPH]: (node, children) =>
				node.content[0].value === '\n' ? <br /> : <p>{children}</p>,
			[BLOCKS.HEADING_1]: (node, children) => {
				return <h1 id={node.id}>{children}</h1>;
			},
			[BLOCKS.HEADING_2]: (node, children) => {
				return <h2 id={node.id}>{children}</h2>;
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return <h3 id={node.id}>{children}</h3>;
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				return <h4 id={node.id}>{children}</h4>;
			},
			[BLOCKS.HEADING_5]: (node, children) => {
				return <h5 id={node.id}>{children}</h5>;
			},
			[BLOCKS.HEADING_6]: (node, children) => {
				return <h6 id={node.id}>{children}</h6>;
			},
			[BLOCKS.EMBEDDED_ASSET]: node => {
				return (
					<div
						className="imgWrapper"
						onClick={() => {
							handleOpenModal(node);
						}}
					>
						<ImageWrapper
							image={node.data.target}
							imageStyles={{
								margin: '0 auto',
								width: '100%',
								objectFit: 'contain',
								cursor: 'pointer',
							}}
						/>
					</div>
				);
			},
			[BLOCKS.EMBEDDED_ENTRY]: node => {
				const data = node.data.target;
				if (data.__typename === 'ContentfulVideoEmbed') {
					return <RenderVideoEmbed data={data} />;
				}
			},
			[BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
			[BLOCKS.TABLE]: (node, children) => {
				return (
					<TableWrapper>
						<StyledTable>
							<thead>
								<StyledTr>
									{children[0].props.children.map(tableHeader => {
										return <StyledTh>{tableHeader}</StyledTh>;
									})}
								</StyledTr>
							</thead>
							<tbody>
								{children.map((tableCell, index) => {
									if (index === 0) {
										return null;
									}
									return (
										<StyledTr>
											{tableCell.props.children.map(item => {
												return <StyledTd>{item}</StyledTd>;
											})}
										</StyledTr>
									);
								})}
							</tbody>
						</StyledTable>
					</TableWrapper>
				);
			},
		},
		renderText: text =>
			text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
	};

	useEffect(() => {
		let timer = setTimeout(() => {
			setTocHeight(document.getElementById('table-of-content')?.clientHeight);
		}, 50);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Wrapper blogContent={blogContent} tocHeight={tocHeight}>
			{content && renderRichText(content, options)}
			<StyledReactModal
				isOpen={modal}
				closeTimeoutMS={500}
				shouldCloseOnOverlayClick={true}
				style={customStyles}
				onRequestClose={handleOpenModal}
				shouldCloseOnEsc={true}
				portalClassName="portal-class"
			>
				<div className={`modal-default ${modal && 'open'}`}>
					<button onClick={handleOpenModal} className="close-button">
						×
					</button>
					<Img
						fluid={currentlyOpenedImage}
						imageStyle={{
							width: '100%',
							height: '100%',
						}}
					/>
				</div>
			</StyledReactModal>
		</Wrapper>
	);
};

export default RichTextRenderer;
