import React from 'react';

const generateYoutubeEmbedLink = (url, autoplay) => {
	let videoId;
	const path = url.pathname;
	let dirs = path.split('/');
	if (url.hostname === 'youtu.be') {
		videoId = dirs[1];
	} else {
		videoId = url.searchParams.get('v') || '';
		if (!videoId) {
			if (dirs.includes('embed')) {
				videoId = dirs[2];
			} else if (dirs.includes('v')) {
				videoId = dirs[2];
			} else if (dirs.includes('attribution_link')) {
				dirs = url.search.split('%');
				videoId = dirs[3].substr(2);
			}
		}
	}

	return `https://www.youtube.com/embed/${videoId}${
		autoplay ? '?autoplay=1' : ''
	}`;
};

const generateVimeoEmbedLink = url => {
	const path = url.pathname;
	const dirs = path.split('/');
	const videoId = dirs[1];
	return `https://player.vimeo.com/video/${videoId}`;
};

const generateLoomVideoEmbedLink = url => {
	const path = url.pathname;
	const dirs = path.split('/');
	dirs[1] = 'embed';
	const newPath = dirs.join('/');
	return url.origin + newPath;
};

export const RenderVideoEmbed = ({
	data: { host, videoUrl: url },
	autoplay,
}) => {
	let embedURL = '';
	try {
		const urlObj = new URL(url);
		if (host === 'Youtube') {
			embedURL = generateYoutubeEmbedLink(urlObj, autoplay);
		} else if (host === 'Vimeo') {
			embedURL = generateVimeoEmbedLink(urlObj, autoplay);
		} else if (host === 'Loom') {
			embedURL = generateLoomVideoEmbedLink(urlObj, autoplay);
		} else {
			embedURL = urlObj.toString();
		}
	} catch (err) {
		embedURL = '';
	}

	return embedURL ? <Iframe src={embedURL} autoplay={autoplay} /> : '';
};

const Iframe = ({ src, autoplay }) => {
	return (
		<div
			style={{
				position: 'relative',
				paddingBottom: '56.25%' /* 16:9 */,
				height: 0,
			}}
		>
			<iframe
				title="embeddedEntry"
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					borderRadius: '10px',
				}}
				src={src}
				frameBorder="0"
				allow={`fullscreen;${autoplay ? ' autoplay;' : ''}`}
				frameborder="0"
				allowfullscreen="true"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
			/>
		</div>
	);
};
