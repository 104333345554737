import styled from 'styled-components';
import { Link } from 'gatsby';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Button = styled(Link)`
	border-radius: 10px;
	box-sizing: border-box;
	display: inline-block;
	text-decoration: none;
	color: ${props => (props.color ? props.color : setColors.white)};
	color: ${props => props.outline && (props.btnColor || setColors.themeBlue)};
	&:hover {
		background-color: ${props =>
			props.btnColor ? props.btnColor : setColors.themeBlue};
		color: ${props => (props.color ? props.color : setColors.white)};
	}
	outline: none;
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.bold};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: -0.11px;
	text-align: center;
	border: 3px solid
		${props => (props.btnColor ? props.btnColor : setColors.themeBlue)};
	background-color: ${props =>
		props.outline
			? 'transparent'
			: props.btnColor
			? props.btnColor
			: setColors.themeBlue};
	padding: 16px 25px;
`;
export const PlayButton = styled(Link)`
	display: flex;
	align-items: center;
	font-size: ${setFontSize.tiny};
	color: ${props => (props.btnColor ? props.btnColor : setColors.black)};
	text-decoration: none;
	img {
		width: 45px;
		margin: 0px 8px;
	}
	${mediaQuery.mobileXL`
		img{
			margin-left: 0px;
		}
		margin-top: 18px
	`}
`;
