import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../../../utils/helpers';
import BGImage from '../../../../images/bg/trial-section-desktop-bg.png';

export const Wrapper = styled.div`
	background-size: 100% 100%;
	box-shadow: 0 6px 15px 0 rgb(31 117 255 / 40%);
	background-image: linear-gradient(
		to bottom,
		${setColors.darkBlueGradiant},
		${setColors.lightBlueGradiant}
	);
	border-radius: 25px;
`;

export const Content = styled.div`
	background-repeat: repeat;
	background-image: url(${BGImage});
	background-attachment: scroll;
	background-position: center center;
	background-size: contain;
	padding: 80px 110px 100px 110px;
	box-sizing: border-box;
	${mediaQuery.tabletXS`padding: 50px 10px 30px 10px!important;`}
`;

export const TextWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 3% 0 0;
	box-sizing: border-box;
	${mediaQuery.laptopXL`padding: 0 1% 0 0;`}
	${mediaQuery.tabletL`padding: 0 2% 0 0;`}
	${mediaQuery.mobileXL`padding: 0;width: 100%;display: block!important;`}
`;

export const ButtoWrapper = styled.div`
	margin: 0 auto;
	padding: 0;
	width: 100%;
	text-align: center;
	a {
		width: 50%;
		${mediaQuery.mobileXL`width: 100%;`}
	}
`;

export const CTAText = styled.div`
	margin: 0 auto;
	text-align: center;
	width: 100%;
	font-family: ${setFontFamily.black};
	font-size: ${setFontSize.mediumLarge};
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${setColors.white};
	padding: 1% 0 58px 0;
	box-sizing: border-box;
	${mediaQuery.laptopXL`font-size: ${setFontSize.medium}`}
	${mediaQuery.tabletL`font-size: ${setFontSize.veryLarge};`}
	${mediaQuery.tabletM`font-size: ${setFontSize.small}`}
	${mediaQuery.mobileXL`font-size: ${setFontSize.large};
    text-align: center;
    margin: 0 auto;`}
	${mediaQuery.mobileM`margin: 4% auto;`}
`;

export const NumberContainer = styled.div`
	img {
		vertical-align: middle;
		display: inline-block;
		width: 57px;
		height: 57px;
	}
`;
