import styled, { css } from 'styled-components';
import {
	mediaQuery,
	setColors,
	SetContainerWidth,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	width: ${SetContainerWidth.fullWidth};
	display: grid;
	align-items: ${props => props.cardAlignmet};
	grid-template-columns: ${props => props.gridLayout};
	direction: ${props => props.invert && 'rtl'};
	padding: ${props =>
		props.noBottomPadding ? '60px 0 0 0' : '60px 0 120px 0'} !important;
	${mediaQuery.tabletL`padding: ${props =>
		props.noBottomPadding ? '40px 0 0 0' : '40px 0 80px 0'} !important;`}
	${mediaQuery.mobileXL`padding: ${props =>
		props.noBottomPadding
			? '50px 0 0 0'
			: '50px 0 100px 0'} !important; grid-template-columns: 1fr;`};
`;

export const ImageWrapper = styled.div`
	display: ${props => (props.isActive ? 'block' : 'none')};
	padding: 0 0 0 0;
	img,
	video {
		border-radius: 10px;
	}
	${mediaQuery.mobileXL`padding: 8% 0!important;
    `}
`;
export const TextWrapper = styled.div`
	${({ cardTheme, isLogo, invert, theme }) => css`
		max-height: 100%;
		overflow-y: auto;
		padding-left: 10px;
		padding: 0 0 0 0 !important;
		direction: ${invert && 'ltr'};
		${mediaQuery.mobileXL`padding: 8% 0!important;`};

		${theme.fontNormal};
		color: ${setColors.lightBlack};
		h4 {
			font-weight: ${setFontWeight.bold};
			line-height: 1;
			text-align: left;
			margin: 0;
		}

		p {
			width: ${SetContainerWidth.fullWidth};
			font-family: ${setFontFamily.book};
			font-size: ${setFontSize.tiny};
			font-weight: ${setFontWeight.normal};
			line-height: 1.5;
			text-align: left;
			margin: 0;
			padding-left: ${isLogo ? '70px' : '0'} !important;
			margin-right: -20px !important;
			margin-top: 5px !important;
			${mediaQuery.laptopM`font-size: ${setFontSize.veryTiny} !important;`}
			${mediaQuery.tabletL`font-size: ${setFontSize.veryTiny} !important;`}
		}

		.active {
			box-shadow: ${cardTheme === 'light'
				? `0 0 28px ${setColors.blackShadow}`
				: 'none'} !important;
			background: ${cardTheme === 'light'
				? setColors.white
				: setColors.black} !important;
			color: ${cardTheme === 'light' ? setColors.lightBlack : setColors.white};
			opacity: 1 !important;
			cursor: pointer;
			outline: none;
		}
	`}
`;

export const FeatureCard = styled.div`
	border-radius: ${props => props.borderRadius} !important;
	margin: 15px auto;
	padding: ${props => props.cardPadding};
	width: 90% !important;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	img {
		padding-right: 1%;
		height: 38px;
		width: 36px;
		${mediaQuery.laptopM`height: 30px;width: 28px;`}
	}
`;

export const Logo = styled.div`
	width: auto !important;
	box-sizing: border-box;
`;
export const Content = styled.div`
	width: ${SetContainerWidth.fullWidth} !important;
	box-sizing: border-box;
	margin: 0px auto;
`;

export const ProgressBar = styled.div`
	${({ isActive }) =>
		css`
			height: 3px;
			background: ${setColors.themeBlue};
			display: ${isActive ? 'block' : 'none'};
			position: absolute;
			bottom: 0;
			left: 0;
		`}
`;
