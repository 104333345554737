import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Button from '../Button/Button';
import { Container } from '../customers/styles';
import * as S from './style';

const FeatureHero = ({
	title,
	subTitle,
	image,
	buttonText,
	buttonLink,
	imgStyle,
	imageWrapperStyle,
	imgSectionWidth,
}) => {
	return (
		<S.Main>
			<Container>
				<S.Wrapper>
					<S.TextContent>
						<S.SectionTitle className="font-black-54">{title}</S.SectionTitle>
						<S.SectionDiscription className="font-book-20">
							{subTitle}
						</S.SectionDiscription>
						{buttonText && (
							<S.SectionButton>
								<Button as="a" href={buttonLink} target="_blank">
									{buttonText}
								</Button>
							</S.SectionButton>
						)}
					</S.TextContent>
					<S.ImageWrapper imgStyle={imgStyle} imgSectionWidth={imgSectionWidth}>
						<Img
							fluid={image.fluid}
							style={imageWrapperStyle}
							alt={image.title}
							fadeIn={false}
							loading="eager"
						></Img>
					</S.ImageWrapper>
				</S.Wrapper>
			</Container>
		</S.Main>
	);
};

FeatureHero.defaultProps = {
	title: 'Oops!!',
	subTitle: "The page that you are looking for dosen't exist.",
	image: {},
	buttonText: '',
	buttonLink: '',
	imgStyle: {},
	imageWrapperStyle: {},
	imgSectionWidth: '',
};

FeatureHero.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	image: PropTypes.object,
	buttonText: PropTypes.string,
	buttonLink: PropTypes.string,
	imgStyle: PropTypes.object,
	imageWrapperStyle: PropTypes.object,
	imgSectionWidth: PropTypes.string,
};

export default FeatureHero;
