/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { checkLink } from '../../../../utils/helpers';
import { IconNext } from '../icons/';
import * as S from './style';

const options = {
	renderMark: {
		[MARKS.BOLD]: text => <strong>{text}</strong>,
	},
	renderNode: {
		[INLINES.HYPERLINK]: ({ data }, children) => {
			const urlType = checkLink(data.uri);
			if (urlType === 0) {
				return (
					<a href={data.uri} target="_blank" rel="noopener noreferrer">
						{children}
					</a>
				);
			} else if (urlType === 1) {
				return (
					<a href={data.uri} target="_self" rel="">
						{children}
					</a>
				);
			} else if (urlType === 2) {
				return <Link to={`${data.uri}/`}>{children}</Link>;
			}
		},
		[BLOCKS.PARAGRAPH]: (node, children) => {
			return <p>{children}</p>;
		},
	},
	renderText: text => {
		return text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]);
	},
};

const Faq = props => {
	const {
		primaryColor,
		title,
		subtitle,
		content,
		maxCount,
		largeTitle,
		titleIcon,
		titleAligmet,
		hideTitleColumn,
	} = props;
	const [data, setData] = useState([]);
	const [showButton, setShowButton] = useState(false);
	const [isAnimating, setIsAnimating] = useState(false);
	useEffect(() => {
		if (maxCount < content.length) {
			setShowButton(true);
			setData(content.slice(0, maxCount));
		} else {
			setData(content);
		}
	}, [content, maxCount]);
	const [isActivePanel, setActivePanel] = useState({});
	const expandSection = index => {
		const answerPanel = document.getElementById(`answer-${index}`);
		const scrollHeight = answerPanel.scrollHeight;
		answerPanel.style.height = scrollHeight + 'px';
		answerPanel.addEventListener('transitionend', function afterTransition() {
			answerPanel.removeEventListener('transitionend', afterTransition);
			answerPanel.style.height = null;
		});
		setActivePanel(prevState => {
			return {
				...prevState,
				[index]: !Boolean(prevState[index]),
			};
		});
	};
	const collapseSection = index => {
		const answerPanel = document.getElementById(`answer-${index}`);
		const scrollHeight = answerPanel.scrollHeight;
		const answerTransition = answerPanel.style.transition;
		answerPanel.style.transition = '';

		requestAnimationFrame(() => {
			answerPanel.style.height = scrollHeight + 'px';
			answerPanel.style.transition = answerTransition;
			requestAnimationFrame(() => {
				answerPanel.style.height = 0 + 'px';
			});
		});
		setActivePanel(prevState => {
			return {
				...prevState,
				[index]: !Boolean(prevState[index]),
			};
		});
	};

	const renderAnswer = answer => {
		if (answer.type === 'text') {
			return answer.content[0];
		}
		if (answer.type === 'link') {
			let htmlText = '';
			answer.content.forEach(node => {
				if (!node.startsWith('{')) {
					htmlText += node;
				} else {
					const url = node.substring(1, node.length - 1).split(',');
					const urlText =
						checkLink(url[1]) === 0
							? `<a href="${url[1]}" target="_blank" rel="noopener noreferrer">${url[0]}</a>`
							: `<a href="${url[1]}">${url[0]}</a>`;
					htmlText += urlText;
				}
			});
			return htmlText;
		} else if (answer.type === 'ul') {
			let htmlText = '';
			answer.content.forEach(node => {
				if (!node.startsWith('{')) {
					htmlText += node;
				} else {
					htmlText += '<br /><ul>';
					const list = node.substring(1, node.length - 1).split(',');
					list.forEach(listItem => (htmlText += `<li>${listItem}</li>`));
					htmlText += '</ul>';
				}
			});
			return htmlText;
		} else if (answer.type === 'ol') {
			let htmlText = '';
			answer.content.forEach(node => {
				if (!node.startsWith('{')) {
					htmlText += node;
				} else {
					htmlText += '<br /><ol>';
					const list = node.substring(1, node.length - 1).split(',');
					list.forEach(listItem => (htmlText += `<li>${listItem}</li>`));
					htmlText += '</ol>';
				}
			});
			return htmlText;
		} else if (answer.type === 'table') {
			let htmlText = '';
			answer.content.forEach(node => {
				if (!node.startsWith('{')) {
					htmlText += node;
				} else {
					htmlText += '<br /><table>';
					const list = node.substring(1, node.length - 1).split(';');
					const rows = list[0];
					const cols = list[1];
					let k = 0;
					htmlText += '<thead>';
					for (let j = 0; j < cols; j++) {
						htmlText += `<th>${list[k + 2]}</th>`;
						k++;
					}
					htmlText += '</thead><tbody>';
					for (let i = 1; i < rows; i++) {
						htmlText += '<tr>';
						for (let j = 0; j < cols; j++) {
							htmlText +=
								i === 0 || j === 0
									? `<th>${list[k + 2]}</th>`
									: `<td>${list[k + 2]}</td>`;
							k++;
						}
						htmlText += '</tr>';
					}
					htmlText += '</tbody></table>';
				}
			});
			return htmlText;
		} else {
			return 'Unsupported answer type';
		}
	};
	const showMax = () => {
		setShowButton(false);
		setData(content);
	};
	return (
		<S.FaqWrapper hideTitleColumn={hideTitleColumn}>
			{!hideTitleColumn && (
				<S.Left
					primaryColor={primaryColor}
					largeTitle={largeTitle}
					titleAligmet={titleAligmet}
				>
					{titleIcon && (
						<img
							src={titleIcon.file.url}
							className={'faq-title-image'}
							alt={titleIcon.title}
						/>
					)}
					<h3>{title}</h3>
					{subtitle && renderRichText(subtitle, options)}
				</S.Left>
			)}
			<S.Right primaryColor={primaryColor}>
				{data.map((item, index) => {
					const checkOpen = isActivePanel[index];
					return (
						<div
							className="faq-container"
							key={index}
							style={{ paddingTop: index === 0 ? '0' : '' }}
						>
							<div
								className={`question-container ${
									checkOpen && 'active-question'
								}`}
								id={index}
								onClick={() => {
									if (isAnimating) {
										return;
									}
									setIsAnimating(true);
									if (checkOpen) collapseSection(index);
									else expandSection(index);
									setTimeout(() => {
										setIsAnimating(false);
									}, 350);
								}}
								style={{ marginTop: index === 0 ? '0' : '' }}
							>
								<div className="question-text">{item.question}</div>
								<div className="down-arrow"></div>
							</div>
							<div
								className={`answer`}
								id={`answer-${index}`}
								style={{ height: '0' }}
							>
								<div className={`answer-container `}>
									<div
										className="answer-text"
										dangerouslySetInnerHTML={{
											__html: renderAnswer(item.answer),
										}}
									></div>
								</div>
							</div>
						</div>
					);
				})}
				{showButton && (
					<>
						<div className="pt-30"></div>
						<span className="show-button" onClick={() => showMax()}>
							Show more <IconNext />
						</span>
					</>
				)}
			</S.Right>
		</S.FaqWrapper>
	);
};

Faq.defaultProps = {
	titleAligmet: 'left',
};

export default Faq;
