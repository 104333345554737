import React from 'react';
import PropTypes from 'prop-types';

const rootStyle = { position: 'relative', userSelect: 'none' };

const IconRoot = ({ height, width, viewBox, children, style, ...rest }) => {
	const composedStyle = { ...rootStyle, ...style };

	return (
		<svg
			viewBox={viewBox}
			width={width}
			height={height}
			style={composedStyle}
			{...rest}
		>
			{children}
		</svg>
	);
};

IconRoot.defaultProps = {
	height: 16,
	width: 16,
};

IconRoot.propTypes = {
	viewBox: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.node.isRequired,
	height: PropTypes.number,
	width: PropTypes.number,
};

export default IconRoot;
