import styled from 'styled-components';
import {
	setColors,
	setFontFamily,
	setFontSize,
	mediaQuery,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	display: grid;
	grid-template-columns: 4fr 8fr;

	svg {
		height: 51px;
		width: 51px;
	}

	${mediaQuery.tabletL`
    	grid-template-columns: 1fr;
    `};
`;

export const Header = styled.div`
	text-align: left;
	padding: 0 15px;
	h2 {
		font-size: ${setFontSize.mediumLarge};
		font-family: ${setFontFamily.black};
		margin: 0;
		letter-spacing: -1.5px;
		line-height: 1.09;
		margin-bottom: 8px;
	}
	p {
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.tiny};
		margin: 0;
		line-height: 1.5;
		margin: 0.85em 0;
	}
	${mediaQuery.laptopXL`
		h2{
			font-size: ${setFontSize.veryLarge};
		}
		p{
			font-size: ${setFontSize.mediumTiny};
		}
	`}
	${mediaQuery.tabletL`
		text-align: center;
		h2{
			font-size: ${setFontSize.mediumSmall};
		}
		p{
			font-size: ${setFontSize.veryTiny};
		}
	`}
	${mediaQuery.mobileXL`
		text-align: center;
		h2{
			font-size: ${setFontSize.large};
		}
	`}
`;

export const CardHolder = styled.div`
	padding: 0 15px;
	margin: 0 auto;
	.subClassHolder {
		padding: 0 20px;
		float: left;
		width: 50%;
		max-width: 340px;
		box-sizing: border-box;
	}
	.subClassHolder:nth-child(even) {
		margin-top: 102px;
	}
	.mobileOnly {
		display: none;
		${mediaQuery.mobileXL`
			display: block;
		`}
	}
	.tabletAbove {
		display: block;
		${mediaQuery.mobileXL`
			display: none;
		`}
	}
	${mediaQuery.tabletL`
		margin: 20px auto;
	`}
	${mediaQuery.mobileXL`
		.mobileOnly {
			display: block;
		}
		.tabletAbove {
			display: none;
		}
		.subClassHolder {
			width: 100%;
			float: unset;
		}
		.subClassHolder:nth-child(even) {
			margin-top: 0;
		}
	`}
`;

export const Card = styled.div`
	box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
	text-align: center;
	align-self: flex-start;
	width: 100%;
	max-width: 340px;
	min-height: 380px;
	border-radius: 7px;
	margin-bottom: 40px;
	background-color: ${setColors.white};
	.number {
		color: ${setColors.themeBlue};
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.huge};
		margin: 0;
		line-height: 1.1em;
		padding: 20px;
	}

	.title {
		font-family: ${setFontFamily.black};
		font-size: ${setFontSize.mediumSmall};
		margin: 0;
	}

	.subtitle {
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.tiny};
		margin: 0.85em 0;
		padding: 0 20px;
		line-height: 1.5;
	}

	${mediaQuery.laptopXL`
		.title{
			font-size: ${setFontSize.tinySmall};
		}
		.subtitle{
			font-size: ${setFontSize.mediumTiny};
		}
	`}
	${mediaQuery.tabletL`
		.subtitle{
			font-size: ${setFontSize.veryTiny};
		}
	`}
`;
